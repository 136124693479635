@import '../../css/constants.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stayOffline {
  max-width: 250px;
  margin-left: 0.4rem;
  .choose {
    display: flex;
    justify-content: space-evenly;

    button {
      margin-right: 0.6rem;
    }
  }
}

.container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 0.4rem 0.4rem 0.4rem 0px;

  @extend .border;
  border-radius: 10px;
  transition: border-color $duration ease;

  position: relative;

  animation-name: fadeIn;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;

  &:hover {
    border-color: white;
  }

  h2,
  p {
    margin: 0.6rem 0px;
    display: inline;
  }

  .imgWrap {
    height: clamp(70px, 4.1vw, 90px);

    img {
      height: 100%;
      border-radius: 10px 0px 0px 10px;
    }
  }
}

.content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0px 1rem;

  cursor: pointer;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  span {
    font-size: 0.9em;
    color: grey;
  }
}

.useDNS {
  color: $green;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
