@import '../../css/constants.scss';

.devices {
  overflow: auto;
  max-height: 40vh;
  padding: 2px 0px;
}

// Targets the 'New Device tags'
.wrapper > div + div {
  display: flex;
  justify-content: space-between;
}
