@import '../../css/constants.scss';

.header {
  display: flex;
  justify-content: space-between;
  margin: 0 15px 0 0;
  align-items: center;
  height: 60px;
  width: 100vw;
  background-color: $dark;
  padding: 0px 20px;
  z-index: 1;
  position: relative;
  padding: 0 80px;
  box-sizing: border-box;
  vertical-align: middle;
  @extend .border;
  @extend .shadow;
  img {
   //justify-content: left;
    width: 148px;
    height: auto;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    margin: 15px 5px;
  }
}
