@import 'css/constants.scss';

.wrapper {
  max-width: 700px;
  margin: auto;

  img {
    max-height: 25px;
    margin-left: 15px;
  }

  span {
    font-family: 'Courier New', Courier, monospace;
  }
}

.spinner {
  margin-bottom: 2rem;
}

.options {
  max-height: #{'min(300px, 30vh)'};
  overflow-y: auto;
}

.network {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $grey;
  transition: all $delay;

  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: transparentize($blue, 0.5);
    transition: 0.1s;
  }

  .icons {
    font-size: 20px;
  }
}

.refreshing {
  background-color: #9c9c9c !important;
  transition: 0.3s;
  cursor: default;
}

.maskedIcon {
  cursor: pointer;
  transition: all $delay;
  color: $white;

  &.active {
    color: $blue;
  }
}
