@import '../../../css/constants.scss';

.toggle {
  margin: 0.6rem auto;

  display: flex;
  justify-content: center;

  div:nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  div:nth-child(2) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  svg {
    margin: 0px 0.4rem;
  }

  div {
    width: 150px;
    background-color: $dark;
    text-align: center;
    padding: 0.6rem;
    @extend .border;

    cursor: pointer;

    transition: background-color $delay;
  }

  div:hover {
    background-color: transparentize($blue, 0.5);
  }

  .active {
    background-color: transparentize($blue, 0.5);
  }
}

.green {
  color: $green;
  font-family: 'Raleway', 'sans-serif' !important;
}

.errorDetail {
  color: $error;
  cursor: pointer;
}
