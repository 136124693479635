@import '../../css/constants.scss';

.wrapper {
  text-align: left;
  margin: 1rem 0px;
}

.bar {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1rem;

  input {
    margin-left: 0px;
    border-radius: 0px 10px 10px 0px;
    width: Min(70%, 300px);
    text-align: left;
    padding: 15px;
  }
  svg {
    padding: 15px;
    margin-right: 0px;
    background-color: darken($grey, 4);
    color: $white;

    border-radius: 10px 0px 0px 10px;
  }
}
