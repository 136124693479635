@import '../../css/constants.scss';

.wrapper {
  text-align: center;
}

.bar {
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    margin-left: 0px;
    border-radius: 0px 10px 10px 0px;
    width: 200px;
    text-align: left;
  }
  svg {
    padding: 16px 15px 15px 15px;
    margin-right: 0px;
    background-color: darken($grey, 4);
    color: $white;

    border-radius: 10px 0px 0px 10px;
  }
}

.split {
  display: flex;
  justify-content: space-between;
}

.error {
  color: $error;
  max-width: 400px;
}
