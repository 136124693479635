@import '../../css/constants.scss';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  height: calc(100vh - 75px);
}

.content {
  background-color: $dark;
  border-radius: 10px;

  min-height: 80%;
  width: 80vw;
  padding: 1.2rem;

  @extend .shadow;
  @extend .border;

  display: flex;
  align-items: center;
  justify-content: center;
}
