@import '../../css/constants.scss';

.wrapper {
  display: flex;
  align-items: center;
}

.container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 0.4rem 0.4rem 0.4rem 0px;

  @extend .border;
  border-radius: 10px;
  transition: border-color $duration ease;

  position: relative;

  animation-name: fadeIn;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;

  &:hover {
    border-color: white;
  }

  h2,
  p {
    margin: 0.6rem 0px;
    display: inline;
  }
}

.imgWrap {
  height: clamp(70px, 4.1vw, 90px);

  img {
    height: 100%;
    border-radius: 10px 0px 0px 10px;
  }
}

.content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0px 1rem;

  cursor: pointer;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  span {
    font-size: 0.9em;
    color: grey;
  }
}

.contentNoCursor {
  @extend .content;
  cursor: not-allowed;
}

.remove {
  z-index: 1;
  position: absolute;
  top: 2px;
  left: 2px;
  cursor: pointer;
  transition: color $duration ease;

  color: grey;

  &:hover {
    color: $blue;
  }
}

.useDNS {
  color: $green;
}
.useIp {
  color: yellow;
}

.offline {
  color: grey;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
