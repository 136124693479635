@import 'css/constants.scss';

// Common to both.
.manager {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top-style: solid;
  border-top-color: rgb(100, 100, 100);
  border-top-width: 1px;

  background-color: $grey;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 0.8rem;

  button {
    margin: 0px;
  }

  input {
    border-radius: 3px;
    padding: 0.6rem;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1rem;
    border: none;
    margin: 10px;
    flex-grow: 1;
    text-align: left;

    background-color: lighten($dark, 4);
  }
}

.activeNetwork {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 1rem;

  border-bottom-color: rgb(100, 100, 100);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: $grey;

  text-align: left;

  h2 {
    margin: 0px;
  }

  .infoNetwork {
    font-size: 0.8rem;
    padding: 0px;
    text-align: left;
  }

  img {
    max-height: 32px;
  }
}

.highlight {
  background-color: transparentize($blue, 0.5) !important;
}
