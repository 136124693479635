@import '../../css/constants.scss';

.wrapper {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  div {
    width: 300px;
    margin-right: 10px;
  }

  img {
    border-radius: 10px;
    @extend .border;
  }
}
