@import '../../css/constants.scss';

.unboxing {
  opacity: 0.6;
  transition: opacity $delay ease;
  cursor: pointer;
  margin-right: 0.2rem;

  &:hover {
    opacity: 1;
  }
}
