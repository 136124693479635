@import '../../css/constants.scss';

.wrapper {
  text-align: center;

  ol,
  ul {
    text-align: left;
  }

  hr {
    margin: 1.5rem 0px;
  }
}
