@import 'css/constants.scss';

.label {
  text-align: center;
  margin: auto;
  padding-top: 2rem;
  width: 30vw;
}
.dots {
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

//////////// Bullets Styles //////////

.bullet {
  background-color: rgb(100, 100, 100);
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}

.activeBullet {
  @extend .bullet;
  background-color: $blue;
  animation-name: appear;
  animation-duration: 0.4s;
  z-index: 2;
  // cursor: pointer;
}

@keyframes appear {
  0% {
    background-color: $dark;
  }

  100% {
    background-color: $blue;
  }
}

//////////// Center Bar Styles //////////

.center {
  width: 0.3rem;
  height: 1.5rem;
  background-color: $dark;
  cursor: pointer;
}
.activeCenter {
  background-color: $blue;
}

.bar {
  position: relative;
  z-index: 2;
  top: -1.1rem;
  background-color: $blue;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  left: 0px;
  width: 100%;
  height: 3px;
  transform-origin: left;
}

.barReference {
  z-index: 1;
  position: relative;
  top: -1.3rem;
  width: 100%;
  height: 2px;
  background-color: rgb(100, 100, 100);
}
