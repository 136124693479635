$header-height: 75px;

$dark: #1C1C1C;
$grey: rgb(50, 50, 50);
$green: #79ce00;
$blue: #30afefff;
$white: rgb(220, 220, 220);
$error: pink;
$background-color: #c8c8c8;

// Duration for most animations.
$duration: 0.2s;
$delay: 0.2s;

.shadow {
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
}

.border {
  border-style: solid;
  border-width: thin;
  border-color: rgba(230, 230, 230, 0.2);
}
