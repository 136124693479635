@import './constants.scss';

body {
  font: 400 1rem 'Raleway', 'sans-serif';
  background-image: url('../img/circles.png');
  background-color: $background-color;
  margin: 0px;
  padding: 0px;
  color: $white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  *::selection {
    color: $green;
    background-color: $white;
  }
}

html {
  font-size: #{'clamp(14px, 0.85vw, 18px)'};
}

.error {
  color: $error;
}

.highlight {
  color: $green;
}

h1 {
  font-size: 2.2rem;
  margin: 0.8rem 0px;
}

h2 {
  font-size: 1.5rem;
  margin: 0.8rem 0px;
}

h3 {
  font-size: 1.2rem;
  margin: 0.4rem 0px;
}

a {
  color: rgb(230, 230, 230);
  transition: all 0.2s;
}

li {
  margin: 0.2rem 0px;
}

a:hover {
  color: $green;
}

button,
.mimic-button {
  font-size: 0.9rem;
  font-family: 'Raleway';
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: $white;
  padding: 0.5rem 1rem;

  margin: 1rem;
  color: $white;
  cursor: pointer;

  transition-property: background-color, color, border-color;
  transition-timing-function: ease;
  transition-duration: $delay;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $blue;
    border-color: $blue;
    background-color: $dark;
  }
}

button:disabled {
  background-color: rgb(30, 30, 30);
  color: rgb(100, 100, 100);
  border-color: rgb(100, 100, 100);
  cursor: not-allowed;
}

.center {
  text-align: center;
}

.pull-left {
  text-align: left;
}

.pull-right {
  text-align: right;
}

input {
  display: block;
  border-radius: 5px;
  background-color: $grey;
  padding: 1rem;
  line-height: 1em;
  border: none;
  width: 100%;
  color: rgb(230, 230, 230);
  outline: none;
  box-sizing: border-box;
  margin: 0.6rem 0px;
}

.muted-button {
  text-decoration: underline;
  border: none;
  text-transform: initial;
  padding: 0px;
  margin: 0.6rem;
}

.icon-button {
  transition: all $delay ease;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
    color: $blue;
  }
}

.hyper-button {
  // mimics a hyperlink but navigates us to a different part of the app.
  // instead of a different website.

  font-weight: initial;
  font-size: 1rem;
  border: none;
  margin: 0px;
  padding: 0px;
  text-transform: initial;
  text-decoration: underline;
  // color: initial;
  color: darken($blue, 3);

  &:hover {
    opacity: 1;
    color: lighten($blue, 1);
    background-color: initial;
  }
}

#scrollbar::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: $grey;
}

#scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

#scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $blue;
}

#scrollbar::-webkit-scrollbar-corner {
  background-color: transparent;
}

/// USED IN ONBOARDING

.diagram {
  width: Min(100%, 600px);
  padding: 1rem;
  box-sizing: border-box;
}

.devices {
  border-bottom-width: thin;
  border-bottom-style: solid;
  border-bottom-color: $white;
  margin: 0.8rem 0px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0px;
  }
}
